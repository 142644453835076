<template>
	<div>
		<v-select
			v-model="question.input_type"
			label="Input type"
            :items="types"
		></v-select>
		<v-checkbox
			v-model="question.disable_input"
			label="Disable input"
		></v-checkbox>
	</div>
</template>

<script>
export default {
	name: "Input",
	props: {
		id: { type: String }, // the id of hte question template
	},
	data: () => {
		return {
			types: ["number", "text"],
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id] || {};
		},
	},
};
</script>
